import React from 'react'
import Link from 'next/link'

export default function Button({
  link = '#',
  width = '2/5',
  bg = 'mainColor',
  text = 'check booking status',
  textColor = 'white',
  widthMD = '2/5',
  fontWeight = 'regular',
  hover = 'hover',
  groupHover = 'white',
  capitalize = 'capitalize',
  mdWidth = 'full',
}) {
  return (
    <div
      className={`bg-${bg} border  border-solid border-mainColor w-${width} md:w-${widthMD} rounded-lg hover:bg-${hover} group lg:px-10 md:w-${mdWidth} h-12 md:h-14`}
    >
      <Link href={link}>
        <a
          className={`w-full h-full flex items-center justify-center text-sm lg:text-base transition duration-500 ease-in-out font-${fontWeight} ${capitalize} text-${textColor} group-hover:text-white`}
        >
          {text}
        </a>
      </Link>
    </div>
  )
}
